import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Jumbotron from "react-bootstrap/Jumbotron"


const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
        <BackgroundImage
          alt="La Casa Student Housing by Urbanworks, Ltd."
          fluid={data.banner.childImageSharp.fluid}
         >
          <Jumbotron style={{backgroundColor: "transparent", minHeight: "300px"}}></Jumbotron>
        </BackgroundImage>
    )}
  />
)

export default Banner
